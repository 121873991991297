import React, { forwardRef } from 'react';
import { Link } from '@remix-run/react';
import { RemixLinkProps } from '@remix-run/react/dist/components';

import { twMerge } from 'tailwind-merge';


type Props = {
  text: string;
  className?: string;
  inputProps?: Omit<React.SelectHTMLAttributes<HTMLButtonElement>, 'className'>;
  onClick: () => void;
};

/**
 * Standard StandardButton input.
 */
export const StandardButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { text,className, inputProps, onClick } = props;

  return (
    <button
      {...inputProps}
      className={twMerge([
        'rounded-md bg-gray-950 px-3.5 py-2 text-sm text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:enabled:scale-[0.99] transition-transform duration-75',
        className,
      ])}
      onClick={onClick}
    >
      {text}
    </button>
  );
});
StandardButton.displayName = 'StandardButton';


type LinkProps = RemixLinkProps & {
  text?: string;
};

/**
 * Standard button, but as an anchor tag.
 */
export const StandardButtonLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return (
    <Link
      {...props}
      ref={ref}
      className={twMerge([
        'rounded-md bg-gray-950 px-3.5 py-2 text-sm text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:scale-[0.99] transition-transform duration-75',
        props.className,
      ])}
    >
      {props?.text || props?.children}
    </Link>
  );
});
StandardButtonLink.displayName = 'StandardButtonLink';
